import { Provider } from '@vgtv/api-client';

import { prepareXandrKeywords } from '../prepareKeywords';
import getUserId from '../getUserId';
import type { AppnexusSettings, PlacementType, GetAdnSettings } from '../types';
import { getScreenOrientation } from '../../../utils/screenOrientation';
import { getJWPlayerProvider } from '../../../utils/player/player';
import { getCategoryMetadataValue } from '../../../utils/player/category';

import { addCuePoint, getAdCuePoint } from './inFeedAdExperiment';
import { getGlobalKeywords } from './keywords';

const getAdnSettings: GetAdnSettings = async (
  context,
  _,
  stream,
  defaults,
  player
) => {
  const { adnPlugin } = context;
  if (!adnPlugin) {
    return null;
  }

  const {
    options,
    siteConfig,
    asset,
    prerollFormat,
    prerollSubtype,
    skipAd,
    muted,
  } = adnPlugin;

  // no ads on brandstudio assets
  if (!siteConfig || !asset || asset.provider !== Provider.VGTV) {
    return null;
  }

  const adCuePoint = getAdCuePoint(asset, options?.experiments ?? []);
  const enableInFeedAdExperiment = adCuePoint
    ? addCuePoint(player, adCuePoint)
    : false;

  const categoryId = asset.category.id;

  function getAdSchedule(placementType: PlacementType, format = placementType) {
    const placement = adnPlugin!.getPlacement(placementType);

    if (placement) {
      return prepareXandrKeywords({
        'id': placement,
        'no-sno-adformat': format,
        'no-sno-news-tv_category': categoryId ? String(categoryId) : undefined,
      });
    }

    return null;
  }

  const enableDynamicAspectRatio =
    options.experiments?.find((e) => e.id === 'ads-aspect-ratio')?.variant ===
      'true' && getScreenOrientation() === 'portrait';

  const globalKeywords = await getGlobalKeywords(
    {
      ...defaults,
      ...siteConfig.keywords,
      newsSection: options.ads?.sectionKeywords,
    },
    asset,
    context.consentManager,
    enableDynamicAspectRatio
  );

  const userAccesses = await context.identityHelper?.getUserAccesses();
  const isTotal = (userAccesses ?? []).includes('total');

  const settings: AppnexusSettings = {
    member: siteConfig.member,
    adPlacements: null,

    options: {
      autoplayMuted: muted && ['bumper', 'sponsorroll'].includes(prerollFormat),
      vpaidcontrols: true,
    },

    adSlots: {
      schedule: {
        preroll: getAdSchedule(
          isTotal &&
            getCategoryMetadataValue(asset?.category, 'series_isSponsored') !==
              'true'
            ? 'sponsorroll_total'
            : prerollSubtype,
          prerollFormat
        ),
        midroll: getAdSchedule('midroll', 'preroll'),
        postroll: getAdSchedule('postroll', 'postroll'),
      },
      params: globalKeywords,
    },
  };

  if (
    !enableDynamicAspectRatio &&
    adnPlugin.hasPlacement('pausead') &&
    getJWPlayerProvider(player) !== 'chromecast'
  ) {
    settings.adPlacements = {
      schedule: {
        pause: {
          sizes: [[1280, 720]],
          invCode: adnPlugin.getPlacement('pausead')!,
          keywords: prepareXandrKeywords({
            'no-sno-adformat': 'netboard_pausead',
            'no-sno-news-tv_category': categoryId
              ? String(categoryId)
              : undefined,
          }),
          maxDisplays: 1,
        },
      },

      params: {
        keywords: globalKeywords,
      },
    };
  }

  if (enableInFeedAdExperiment) {
    Object.assign(settings.adSlots.schedule, {
      preroll: null,
      midroll: settings.adSlots.schedule.preroll,
    });
  } else if (skipAd) {
    settings.adSlots.schedule.preroll = null;
  }

  const userId = getUserId();

  if (userId) {
    settings.adSlots.params.external_uid = userId;

    if (settings.adPlacements) {
      settings.adPlacements.params.keywords = {
        ...settings.adPlacements.params.keywords,
        external_uid: userId,
      };
    }
  }

  return settings;
};

export default getAdnSettings;
